<template>
  <component-dialog
    ref="refPatientContactDialog"
    :dialog-width="addNewContact ? 'large' : 'medium'"
    dialog-type="emptyActions"
    dialog-title="Kontakt verknüpfen"
    primary-label="Hinzufügen"
    :test-id="testId + '-patient-contact'"
  >
    <template #content>
      <div v-if="loading" class="w-full grid place-content-center min-h-32">
        <component-spinner class="h-8 w-8" :test-id="testId + '-patient-contact-dialog'" />
      </div>

      <div v-else class="grid grid-cols-6">
        <div class="col-span-2">
          <div v-if="!addNewContact">
            <component-input
              v-model="addressBookSearch"
              autocomplete="off"
              class="max-w-52"
              placeholder="Suche"
              :test-id="testId + '-patient-contact-dialog-search'"
            >
              <template #icon>
                <component-icon class="text-xl text-gray-600">search</component-icon>
              </template>
            </component-input>

            <div class="p-2 h-72 overflow-y-auto overflow-x-hidden overscroll-contain">
              <ul v-if="filteredAddressBook?.length > 0" class="space-y-2">
                <li
                  v-for="contact in filteredAddressBook"
                  :key="contact.id"
                  class="px-2 py-1 rounded-md group hover:bg-gray-100"
                  :class="{'bg-gray-100': isSelectedContact(contact)}"
                >
                  <button
                    class="truncate w-full flex items-center justify-between"
                    :data-test="testId + '-patient-contact-dialog-select-contact-button'"
                    @focus="selectedContact = contact"
                    @mouseover="selectedContact = contact"
                    @click="selectedContact = contact"
                  >
                    <span class="flex flex-col text-left space-y-0.5">
                      <span v-if="contact.firstname || contact.lastname">
                        {{ contact.title }} {{ contact.firstname }} {{ contact.lastname }}
                      </span>

                      <span v-else>{{ contact.company }}</span>

                      <component-category-badge
                        v-if="patient?.contacts?.filter((pC) => pC.id === contact.id).length > 0"
                        color="green"
                        label="Bereits verknüpft"
                      />
                    </span>

                    <component-icon
                      class="hidden group-hover:block text-gray-600"
                      :class="{'!block': isSelectedContact(contact)}"
                    >
                      chevron_right
                    </component-icon>
                  </button>
                </li>
              </ul>

              <div v-else-if="showNoContactsFound">
                <component-empty-state class="mb-5" :test-id="testId + '-patient-contact-dialog-no-contact-found'">
                  Keine Kontakte gefunden.
                </component-empty-state>
              </div>
            </div>
          </div>
        </div>

        <div class="col-span-4 px-4">
          <div v-if="addNewContact">
            <contacts-contact-form
              class="border-none shadow-none -m-4"
              :patient-id="patient.id"
              :redirect-back="true"
              :complete-form="false"
              :contact="addNewContactDefaultValues"
              :emit-cancel="true"
              :test-id="testId + '-patient-contact-dialog'"
              @submit-has-no-errors="closeDialog()"
              @cancel-button-clicked="closeDialog()"
            />
          </div>
          <div v-else-if="selectedContact">
            <component-contact-card :contact="selectedContact" />

            <component-select
              v-if="!selectedContactAlreadyAdded"
              v-model="form.role"
              class="mt-4"
              label="Funktion (optional)"
              :options="assignmentRole"
              :nullable="true"
              placeholder-text=""
              helper-text="Welche Rolle nimmt dieser Kontakt ein?"
              :test-id="testId + '-patient-contact-dialog-role'"
            />
          </div>
          <div v-else-if="showNoContactsFound">
            <component-button
              label="Kontakt erstellen"
              :test-id="testId + '-patient-contact-dialog-add-new-contact'"
              @click="addNewContact = true"
            />
          </div>
        </div>
      </div>
    </template>

    <template v-if="!addNewContact" #actions>
      <div class="w-full mt-4 flex flex-row-reverse justify-between flex-wrap">
        <div class="flex flex-row-reverse space-x-reverse space-x-3">
          <component-button
            class="p4umc-primary"
            label="Hinzufügen"
            :disabled="
              form.processing || selectedContactAlreadyAdded || showNoContactsFound || selectedContact === null
            "
            :test-id="testId + '-patient-contact-dialog-save'"
            @click="save()"
          />

          <component-button
            label="Schließen"
            :test-id="testId + '-patient-contact-dialog-close'"
            @click="closeDialog()"
          />
        </div>

        <component-button
          class="p4umc-flat"
          label="Kontakt erstellen"
          :test-id="testId + '-patient-contact-dialog-create-contact'"
          @click="addNewContact = true"
        />
      </div>
    </template>

    <template #info-icon>
      <component-info-icon>
        <template #content>
          Stellen Sie eine Verbindung zwischen dem Patienten und Ärzten, Pflegediensten oder Heimen aus Ihrem Adressbuch
          her.
        </template>
      </component-info-icon>
    </template>
  </component-dialog>
</template>

<script>
  import {computed, inject, ref, watch} from "vue";
  import {useForm} from "@inertiajs/vue3";

  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentCategoryBadge from "@components/Badges/CategoryBadge.vue";
  import ComponentContactCard from "@components/Cards/ContactCard.vue";
  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import ComponentEmptyState from "@components/EmptyState/EmptyState.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentInfoIcon from "@components/Icons/InfoIcon.vue";
  import ComponentInput from "@components/Inputs/Input.vue";
  import ComponentSelect from "@components/Selects/Select.vue";
  import ComponentSpinner from "@components/Spinner.vue";

  import {assignmentRole} from "@pages/Contact/Enums/Enums.js";

  import ContactsContactForm from "@pages/Contact/Components/ContactForm.vue";

  export default {
    name: "ComponentPatientContactDialog",

    components: {
      ComponentButton,
      ComponentCategoryBadge,
      ComponentContactCard,
      ComponentDialog,
      ComponentEmptyState,
      ComponentIcon,
      ComponentInfoIcon,
      ComponentInput,
      ComponentSelect,
      ComponentSpinner,
      ContactsContactForm,
    },

    props: {
      patient: {
        type: Object,
        required: true,
      },
    },

    setup(props) {
      const broadcast = inject("$broadcast");
      const axios = inject("$axios");

      const refPatientContactDialog = ref(null);

      const form = useForm({
        role: null,
      });

      let abortController = null;
      const addressBook = ref([]);
      const loading = ref(false);

      const addressBookSearch = ref(null);
      const selectedContact = ref(null);
      const addNewContact = ref(false);
      const addNewContactDefaultValues = ref(null);

      const filteredAddressBook = computed(() => {
        if (addressBookSearch.value === null || addressBookSearch.value.length === 0) {
          return addressBook.value;
        }

        return (
          addressBook.value.filter((contact) => {
            let searchString = [contact.firstname, contact.lastname, contact.company].join(" ").toLowerCase();
            return addressBookSearch.value
              .toLowerCase()
              .split(" ")
              .every((word) => searchString.includes(word));
          }) ?? []
        );
      });

      const showNoContactsFound = computed(() => {
        return filteredAddressBook.value?.length === 0 && !loading.value;
      });

      const selectedContactAlreadyAdded = computed(
        () => props.patient?.contacts?.filter((pC) => pC.id === selectedContact.value?.id).length > 0,
      );

      watch(addressBookSearch, (newValue) => {
        // select first search result when searching in addressbook
        if (newValue === null || newValue.length === 0) {
          selectedContact.value = null;
        } else {
          selectedContact.value = filteredAddressBook.value[0];
        }
      });

      const isSelectedContact = (contact) => {
        return selectedContact.value !== null && selectedContact.value?.id === contact.id;
      };

      const open = (search = null) => {
        // loading addressBook here
        loading.value = true;
        abortController = new AbortController();

        axios
          .post(
            route("api.contacts.search"),
            {},
            {
              signal: abortController.signal,
            },
          )
          .then((response) => {
            addressBook.value = response?.data?.data ?? [];
            loading.value = false;

            if (typeof search === "string") {
              addressBookSearch.value = search.replace("Dr.", "").replace("Prof.", "").replace("Med.", "").trim();
            } else if (typeof search === "object" && search !== null) {
              addNewContactDefaultValues.value = search;
              addressBookSearch.value = [search.firstname ?? null, search.lastname ?? null]
                .filter((p) => {
                  return p !== null;
                })
                .join(" ");
            }
          });

        refPatientContactDialog.value.open();
      };

      const closeDialog = () => {
        refPatientContactDialog.value.close();
        resetDialog();
      };

      const save = () => {
        form
          .transform((data) => ({
            ...data,
            ...{contact_id: selectedContact.value?.id},
          }))
          .post(route("assignment.store", {patient: props.patient.id}), {
            preserveScroll: true,
            onSuccess: () => {
              resetDialog();
              broadcast.patient.postMessage(props.patient.id, {action: "reload.patient"});
            },
          });
      };

      const resetDialog = () => {
        selectedContact.value = null;
        form.role = null;
        addressBookSearch.value = null;
        addNewContact.value = false;
        addNewContactDefaultValues.value = null;
      };

      return {
        /** enum */
        assignmentRole,

        /** ref */
        refPatientContactDialog,

        /** const */
        loading,
        addNewContact,
        addNewContactDefaultValues,
        form,
        selectedContact,
        addressBookSearch,

        /** computed */
        filteredAddressBook,
        showNoContactsFound,
        selectedContactAlreadyAdded,

        /** function */
        save,
        resetDialog,
        closeDialog,
        isSelectedContact,
        open,
      };
    },
  };
</script>
